import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/widgets/v1/area/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        addArea(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/widgets/v1/area/add',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        updateArea(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .put('/widgets/v1/area/update/'+queryParams.id,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        deleteAreaData(ctx, id){
            return new Promise((resolve, reject) => {
                axios
                  .delete('/widgets/v1/area/delete/'+id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })
        },
        updateOrdering(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .put('/widgets/v1/updateOrdering',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        addData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/widgets/v1/add',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        updateData(ctx,queryParams){
         
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .put('/widgets/v1/update/'+queryParams.id,data,{
                  headers:{
                    'Content-Type': 'multipart/form-data',
                    'dataType':'json'
                  }
                })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        deleteData(ctx,id){
          
            
          return new Promise((resolve, reject) => {
            axios
              .delete('/widgets/v1/delete/'+id)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })

      },
      fetchImages(ctx, queryParams) {
        var data = JSON.stringify(queryParams);
        
        return new Promise((resolve, reject) => {
          axios
            .post('/widgets/v1/image/getall',data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    updateImageOrdering(ctx, queryParams) {
        var data = JSON.stringify(queryParams);
        
        return new Promise((resolve, reject) => {
          axios
            .put('/widgets/v1/image/updateOrdering',data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    
    uploadimages(ctx,queryParams){
      return new Promise((resolve, reject) => {
        axios
          .post('/widgets/v1/image/add/'+queryParams.id,queryParams.formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    fetchImageDelete(ctx,id){
      return new Promise((resolve, reject) => {
        axios
          .delete('/widgets/v1/image/delete/'+id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    }
    }
}