import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useWidgetsArea() {
    const isAreaFormSidebarActive = ref(false);
    const isWidgetFormSidebarActive = ref(false);
    const areas = ref([]);
    const toast = useToast();
    const areaData = ref({});
    const widgetData = ref({});
    const updateArea = (group)=>{
        isAreaFormSidebarActive.value = true;
        areaData.value = group;
    }
    const addArea = ()=>{
        isAreaFormSidebarActive.value = true;
        areaData.value = {}
    }
    const updateWidget = (nav)=>{
        isWidgetFormSidebarActive.value = true;
        widgetData.value = nav;
    }
    const addWidget = (area_id)=>{
        isWidgetFormSidebarActive.value = true;
        widgetData.value = {
            area_id:area_id
        }
    }
    const refetchData = ()=>{
        store
        .dispatch('app-widget/fetchData', {})
        .then(response => {
            
            const  data  = response.data.data
            areas.value =data;
            console.log('areas',areas.value);
            //const total = response.data.total?response.data.total:cats.length;
            //callback(cats)
    
        })
        .catch((error) => {
            console.log('error',error);
            toast({
            component: ToastificationContent,
            props: {
                title: "Error fetching Group' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
            })
        })
            
    }
    refetchData();
    
      const undateOrdering = (key)=>{
        console.log(areas.value[key]);
        let widgets = areas.value[key].widgets;
         
        store.dispatch('app-widget/updateOrdering',widgets)
        
        
    }

    const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-widget/deleteData',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Navigation",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
      const deleteAreaData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-widget/deleteAreaData',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Group Navigation",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
      return {
        areas,
        areaData,
        isAreaFormSidebarActive,
        isWidgetFormSidebarActive,
        undateOrdering,
        updateArea,
        addArea,
        refetchData,
        addWidget,
        updateWidget,
        widgetData,
        deleteData,
        deleteAreaData
      }
    
}